export default class LazyComponent {
	notFoundElementId = 'realizations-not-found';

	lazyComponentElementId = 'realizations-lazy-loading';

	element;

	notFoundElement;

	constructor() {
		this.element = document.getElementById(this.lazyComponentElementId);
		this.notFoundElement = document.getElementById(this.notFoundElementId);
	}

	render(realizationData) {
		this.notFoundElement.hidden = true;
		for (let i = 0; i < realizationData.length; i++) {
			this.element.innerHTML += this.template(realizationData[i]);
		}
	}

	renderNotFound() {
		this.clear();
		this.notFoundElement.hidden = false;
	}

	clear() {
		this.element.innerHTML = '';
	}

	template(data) {
		const typeNames = data.types_names.split(',');
		const typeName = typeNames.filter((e) => e !== 'Z wideo');
		const categories = data.categories_names.split(',');
		const type = typeName.map((typ) => `<div class="realization__filter">${typ}</div>`).join('');
		const category = categories.map((cat) => `<div class="realization__category">${cat}</div>`).join('');
		if (typeNames.includes('Z wideo')) {
			return `
            <a class="realization Realization__item"  href="${data.link}" data-as="fade-left" title="${data.name}">
				<img src="${data.image}" alt="${data.name}" title="${data.name} - Meble Flaming" />
				<div class="Realization__item-about">
					<h5 class="realization__title">${data.name}</h5> 
					<div class="realization__filter-wrapper">
					${type}
					</div>
					<div class="realization__category-wrapper">
					${category}
					</div>
					<div class="realization__category-video">
					<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7.49992 0.333496C6.18138 0.333496 4.89245 0.724489 3.79612 1.45703C2.69979 2.18957 1.84531 3.23077 1.34072 4.44894C0.836141 5.66711 0.704118 7.00756 0.961353 8.30076C1.21859 9.59397 1.85353 10.7819 2.78588 11.7142C3.71823 12.6466 4.90611 13.2815 6.19932 13.5387C7.49253 13.796 8.83297 13.6639 10.0511 13.1594C11.2693 12.6548 12.3105 11.8003 13.0431 10.704C13.7756 9.60764 14.1666 8.3187 14.1666 7.00016C14.1666 6.12468 13.9942 5.25778 13.6591 4.44894C13.3241 3.6401 12.833 2.90517 12.214 2.28612C11.5949 1.66706 10.86 1.176 10.0511 0.840966C9.24231 0.505935 8.3754 0.333496 7.49992 0.333496ZM6.16659 10.0002V4.00016L10.1666 7.00016L6.16659 10.0002Z" fill="white"></path>
					</svg>
					film z realizacji
				</div>
				</div>
            </a>
        	`;
		}
		return `
            <a class="realization Realization__item"  href="${data.link}" data-as="fade-left" title="${data.name}">
				<img src="${data.image}" alt="${data.name}" title="${data.name} - Meble Flaming" />
				<div class="Realization__item-about">
					<h5 class="realization__title">${data.name}</h5> 
					<div class="realization__filter-wrapper">
					${type}
					</div>
					<div class="realization__category-wrapper">
					${category}
					</div>
				</div>
            </a>
        	`;
	}
}
