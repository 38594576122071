export default class Category {
	id;

	name;

	element;

	isActive = false;

	constructor(id, name, element) {
		this.id = id;
		this.name = name;
		this.element = element;

		this.elementInit();
	}

	elementInit() {
		this.element.addEventListener('click', this.onCategoryClick);
	}

	onCategoryClick() {
		if (this.isActive) {
			this.isActive = false;
			this.classList.remove('Realizations__category_btn--active');
			return;
		}

		this.isActive = true;
		this.classList.add('Realizations__category_btn--active');
	}
}
