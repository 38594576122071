import axios from 'axios';
import LazyComponent from './LazyComponent';
import LoadingComponent from './LoadingComponent';

export default class RealizationService {
	categoryService;

	typeService;

	lazyComponent;

	loadingComponent;

	categoryFilters = null;

	typeFilters = [];

	page = 0;

	ready = true;

	constructor(categoryService, typeService) {
		this.categoryService = categoryService;
		this.typeService = typeService;

		this.categoryService.addEventListerToCategories(() => this.updateFilters());
		this.categoryService.addEventListenerToClearBtn(() => this.updateFilters());
		this.typeService.addEventListerToTypes(() => this.updateFilters());
		this.lazyComponent = new LazyComponent();
		this.loadingComponent = new LoadingComponent();
		this.loadingComponent.addListenerOnVisibility(() => {
			if (this.ready) {
				this.ready = false;
				this.fetchRealizations();
				setTimeout(() => {
					this.ready = true;
				}, 1000);
			}
		});
		if (this.ready) {
			this.ready = false;
			this.fetchRealizations();
			setTimeout(() => {
				this.ready = true;
			}, 1000);
		}
	}

	updateFilters() {
		this.page = 0;
		this.lazyComponent.hidden = false;
		this.categoryFilters = this.categoryService.filterList;
		this.typeFilters = this.typeService.filterList;

		this.updateRealizations();
	}

	async fetchRealizations() {
		const categoriesId = this.categoryFilters;
		const typesId = this.typeFilters.join(',');

		let url = `/index.php?option=com_realizations&view=contents&task=contents.fetch&limit=6&start=${this.page * 6}`;
		this.page += 1;
		if (categoriesId) url += `&categories_id=${categoriesId}`;
		if (typesId) url += `&types_id=${typesId}`;
		const res = await axios.get(url);

		if (res.data.length === 0) {
			this.loadingComponent.removeListenerOnVisibility(() => {
				this.fetchRealizations();
			});

			return;
		}
		if (res.data.length < 6) {
			this.loadingComponent.element.hidden = true;
			this.ready = false;
		}
		this.lazyComponent.render(res.data);
	}

	async updateRealizations() {
		this.ready = true;
		this.lazyComponent.clear();
		this.lazyComponent.notFoundElement.hidden = true;
		this.loadingComponent.element.hidden = false;
		const categoriesId = this.categoryFilters;
		const typesId = this.typeFilters.join(',');
		if (categoriesId === null) {
			this.categoryService.categoryClearBtn.element.classList.add('Realizations__category_btn--active');
		}

		let url = `/index.php?option=com_realizations&view=contents&task=contents.fetch&limit=6&start=${this.page * 6}`;
		this.page += 1;
		if (categoriesId) url += `&categories_id=${categoriesId}`;
		if (typesId) url += `&types_id=${typesId}`;
		const res = await axios.get(url);

		if (res.data.length === 0) {
			this.loadingComponent.element.hidden = true;
			this.ready = false;
			this.lazyComponent.renderNotFound();
			this.loadingComponent.removeListenerOnVisibility(() => {
				this.fetchRealizations();
			});
			return;
		}
		if (res.data.length < 6) {
			this.loadingComponent.element.hidden = true;
			this.ready = false;
		}
		this.lazyComponent.clear();
		if (res.data[0].categories.split(',').includes(this.categoryFilters) || this.categoryFilters === null) this.lazyComponent.render(res.data);

		if (this.ready) {
			this.ready = false;
			this.fetchRealizations();
			setTimeout(() => {
				this.ready = true;
			}, 1000);
		}
	}
}
