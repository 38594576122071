/* eslint-disable */

import {
	Modal
} from './Modal'

export default function () {
	const modal = new Modal(document.querySelector('.quick-quote-wrapper'))
	const buttons = [...document.querySelectorAll('.btn.btn-primary:not([data-action="submit"])')];
	const thanksPage = document.querySelector('.thankspage')
	const errorPage = document.querySelector('.formerrorpage')

	buttons.forEach(button => {
		button.addEventListener('click', () => {
			modal.openModal()
			thanksPage.classList.remove('endFormVisible')
			errorPage.classList.remove('endFormVisible')
		})
	})
}
