import { Buffer } from 'buffer';

export default function hideEmail() {
	const emails = document.querySelectorAll('customfield-hidden-email');
	emails.forEach((email) => {
		const name = email.getAttribute('data-name');
		const domain = email.getAttribute('data-domain');
		let text = Buffer.from(email.getAttribute('data-text'), 'base64');
		text = text.toString();
		text = text.toString('base64');
		// eslint-disable-next-line
		email.innerHTML = /* html */`<a href="mailto:${name}@${domain}">${text}</a>`;
	});
}
