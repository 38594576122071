/* eslint-disable */
import SmoothScroll from 'smooth-scroll';

const scroll = new SmoothScroll();

export default function (fixedMenuHeight = 0) {
	const url = new URL(window.location.href);
	const scrollToElement = url.searchParams.get('scrollTo');

	if (scrollToElement) {
		scroll.animateScroll(document.querySelector(`#${scrollToElement}`), null, {
			offset: fixedMenuHeight,
			speed: 1000,
		});
	}

	[...document.querySelectorAll('a[data-scroll-to]')].forEach((link) => {
		link.addEventListener('click', (e) => {
			e.preventDefault();
			let addToScroll = 0;
			if (link.classList.contains('amazing-checkbox')) addToScroll = 170;
			scroll.animateScroll(document.querySelector(link.getAttribute('href')), null, {
				offset: addToScroll - fixedMenuHeight,
				speed: 1000,
			});
		});
	});
}
