/* eslint-disable */
export default function (configUser) {
	const self = {};
	const config = {
		start: {
			ratio: (configUser && configUser.start && configUser.start.ratio) ? configUser.start.ratio : 0.75,
			dataName: (configUser && configUser.start && configUser.start.dataName) ? configUser.start.dataName : 'as',
			visibleAfterLoading: (configUser && configUser.start && configUser.start.visibleAfterLoading) ? configUser.start.visibleAfterLoading : false,
			waitForScroll: {
				on: (configUser && configUser.start && configUser.start.waitForScroll && configUser.start.waitForScroll.on !== undefined) ? configUser.start.waitForScroll.on : true,
				ratio: (configUser && configUser.start && configUser.start.waitForScroll && configUser.start.waitForScroll.ratio) ? configUser.start.waitForScroll.ratio : 1,
				delay: (configUser && configUser.start && configUser.start.waitForScroll && configUser.start.waitForScroll.delay) ? configUser.start.waitForScroll.delay : 200,
			},
		},
		animation: (configUser && configUser.animation) ? configUser.animation : false,
	};
	const asElement = function (element, animation) {
		const self = {};
		const startElement = animation.getStartStyle();
		const finishElement = animation.getFinishStyle();

		self.init = function () {
			self.startStyle();
			self.addScrollEvent();
			self.addRetryFunction();
			self.startAnimate();
		};

		self.startStyle = function () {
			startElement.forEach((o) => {
				element.style[o.element] = o.value;
			});
		};

		self.animate = function () {
			finishElement.forEach((o) => {
				element.style[o.element] = o.value;
			});
		};

		self.startAnimate = function () {
			if (!config.start.waitForScroll.on && self.position() < config.start.waitForScroll.ratio) {
				setTimeout(() => {
					self.animate();
				}, config.start.waitForScroll.delay);
			}
		};

		self.position = function () {
			return element.getBoundingClientRect().top / window.innerHeight;
		};

		self.addScrollEvent = function () {
			window.addEventListener('scroll', throttle(scrollFuncition, 200));
		};

		var scrollFuncition = function () {
			if (self.position() < config.start.ratio) {
				self.animate();
			}
		};

		var throttle = function (callback, time) {
			let wait = false;
			return function () {
				if (!wait) {
					setTimeout(() => {
						callback();
						wait = false;
					}, time);
				}
				wait = true;
			};
		};

		self.addRetryFunction = function () {
			element.asRetry = function () {
				self.startStyle();
				setTimeout(() => {
					self.animate();
				}, 0);
			};
		};

		self.init();

		return self;
	};

	const animation = function (data) {
		const self = {};
		const startStyle = [];
		const finishStyle = [];

		self.init = function () {
			data = data.split(';');
			const first = data[0];
			if (config.animation[first]) {
				data.shift();
				data = config.animation[first].split(';').concat(data);
			}
			data.forEach((e) => {
				if (e.indexOf(':') > -1) {
					createStartStyle(e);
					createFinishStyle(e);
				}
			});
		};

		var createStartStyle = function (e) {
			const styleS = {};

			styleS.element = e.split(':')[0].replace(/\s*/gi, '');
			styleS.value = e.split(':')[1].split('->')[0];

			if (styleS.element !== undefined && styleS.value !== undefined) {
				startStyle.push(styleS);
			}
		};

		var createFinishStyle = function (e) {
			const styleF = {};

			styleF.element = e.split(':')[0].replace(/\s*/gi, '');
			styleF.value = e.split(':')[1].split('->')[1];

			if (styleF.element !== undefined && styleF.value !== undefined) {
				finishStyle.push(styleF);
			}
		};

		self.getStartStyle = function () {
			return startStyle;
		};

		self.getFinishStyle = function () {
			return finishStyle;
		};

		self.init();

		return self;
	};

	self.init = function () {
		if (document.querySelector(`[data-${config.start.dataName}]`)) {
			const dataAs = document.querySelectorAll(`[data-${config.start.dataName}]`);
			const tab = [];
			for (let i = 0; i < dataAs.length; i++) {
				tab[i] = dataAs[i];
			}
			tab.forEach((e) => {
				const data = e.dataset[config.start.dataName];
				if ((e.getBoundingClientRect().top / window.innerHeight) >= 1 && !config.start.visibleAfterLoading) {
					asElement(e, animation(data));
				} else if (config.start.visibleAfterLoading) {
					asElement(e, animation(data));
				}
			});
		}
	};

	self.init();

	return self;
}
