/* eslint-disable */

export class Modal {
	constructor(modal) {
		this.modal = modal
		this.closeButton = modal.querySelector('.quick-close-btn')
		this.closingViewButtonsExit = [...modal.querySelectorAll('.exit-btn')]
		this.exitView = modal.querySelector('.closing-window')
		this.backToFormButton = modal.querySelector('.back-to-form')
		this.prevBtns = modal.querySelectorAll('[data-action="previous"]')
		this.nextBtns = modal.querySelectorAll('[data-action="next"]')
		this.initListeners()
	}
	openModal = () => {
		this.modal.classList.add('visible')
	}
	exitModalPrompt = () => {
		this.exitView.classList.add('visible-flex')
		this.exitView.classList.remove('close')
	}

	closeExitView = () => {
		this.exitView.classList.add('close')
		this.exitView.classList.remove('visible-flex')
	}
	exitModal = () => {
		this.modal.classList.remove('visible')
		this.exitView.classList.remove('visible-flex')
	}

	initListeners = () => {
		this.closeButton.addEventListener('click', this.exitModalPrompt)
		this.closingViewButtonsExit.forEach(button => {
			button.addEventListener('click', this.exitModal)
		})
		this.backToFormButton.addEventListener('click', this.closeExitView)
	}
}
