import Type from './Type';

export default class TypeFactory {
	typeClassName = 'Realizations__type_btn';

	createTypes() {
		const typeButtonList = document.getElementsByClassName(this.typeClassName);
		const typeList = [];
		for (let i = 0; i < typeButtonList.length; i++) {
			const typeButton = typeButtonList[i];
			typeList.push(new Type(typeButton.id, typeButton.name, typeButton));
		}
		return typeList;
	}
}
