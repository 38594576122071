/* eslint-disable */

class TabWrapper{
    constructor(wrapper){
        this.wrapper = wrapper
        this.tabs = [...wrapper.querySelectorAll('[data-tab-button]')].map(el => new Tab(this, el.dataset.tabButton))
    }
    handleOpen (element) {
		this.tabs.map(el => {
			if (el.button === element) el.open()
			else el.close()
		})
	}
}
class Tab{
    constructor(tabWrapper, key){
        this.tabWrapper = tabWrapper
        this.button = tabWrapper.wrapper.querySelector(`[data-tab-button='${key}']`)
        this.content = tabWrapper.wrapper.querySelector(`[data-tab-content='${key}']`)
        this.isActive = this.button.classList.contains('active')
		this.renderListener()
    }

    renderListener(){
        this.button.addEventListener('click', () => this.tabWrapper.handleOpen(this.button))
    }
    open () {
		this.isActive = true
		this.render()
	}
	close () {
		this.isActive = false
		this.render()
	}
    render(){
        if (this.isActive) {
			this.content.classList.add('active')
			this.button.classList.add('active')
		} else {
			this.content.classList.remove('active')
			this.button.classList.remove('active')
		}
    }
}
window.addEventListener('DOMContentLoaded', () => {
	const el = document.querySelector('.tab')
	if (el) {
		window.tabWrapper = new TabWrapper(document.querySelector('.tab'))
	}
})