/* eslint-disable */
export default function (navbarSelector = '[data-navbar-stick]', className = 'fixed-top') {
	const navbar = document.querySelector(navbarSelector);
	const offset = window.pageYOffset + navbar.getBoundingClientRect().top;

	function scrollHandler() {
		if (window.pageYOffset > offset && window.innerWidth >= 1200) {
			navbar.classList.add(className);
			document.body.style.paddingTop = `${navbar.offsetHeight}px`;
		} else {
			navbar.classList.remove(className);
			document.body.style.paddingTop = null;
		}
	}

	scrollHandler();
	window.addEventListener('scroll', scrollHandler);
}
