/* eslint-disable */
class Timeline {
	get maxHeight () {
		return this.timeline.offsetHeight
	}
	get deviceHeight () {
		return window.innerHeight
	}
	get top () {
		return this.timelineActive.getBoundingClientRect().top
	}
	get height () {
		return this.deviceHeight / 2 - this.top
	}
	init () {
		this.timeline = document.getElementById('timeline')
		this.timelineActive = document.getElementById('timeline-active')
		if (this.timeline) window.addEventListener('scroll', this.windowScrollHandler.bind(this))
	}
	setHeight (height) {
		this.timelineActive.style.height = height + 'px'
	}
	windowScrollHandler () {
		if (this.height < 0) this.setHeight(0)
		else if (this.height > this.maxHeight) this.setHeight(this.maxHeight)
		else this.setHeight(this.height)
	}
}

const timeline = new Timeline()
document.addEventListener('DOMContentLoaded', timeline.init.bind(timeline))
