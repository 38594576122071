export default class LoadingComponent {
	elementId = 'realizations-loading';

	element;

	constructor() {
		this.element = document.getElementById(this.elementId);
	}

	addListenerOnVisibility(func) {
		document.addEventListener('scroll', () => {
			if (this.isInViewport()) func();
		});
	}

	removeListenerOnVisibility(func) {
		document.removeEventListener('scroll', () => {
			if (this.isInViewport()) func();
		});
	}

	isInViewport() {
		const rect = this.element.getBoundingClientRect();
		return (
			rect.top >= 0
			&& rect.left >= 0
			&& rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
			&& rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}
}
