/* eslint-disable */
const CounterStatus = {
	WAIT: 0,
	DURING_COUNTING: 1,
	DONE: 2,
};
class Counter {
	constructor() {
		this.status = CounterStatus.WAIT;
	}

	get isWaiting() {
		return this.status === CounterStatus.WAIT;
	}

	get isDone() {
		return this.status === CounterStatus.DONE;
	}

	get position() {
		return this.element.getBoundingClientRect().top / window.innerHeight;
	}

	get isVisible() {
		return this.element.offsetParent &&	 this.position < 0.95;
	}

	get isDown() {
		return this.element.offsetParent &&	this.position >= 1;
	}

	animate() {
		this.status = CounterStatus.DURING_COUNTING;
		const step = 200;
		const intervalTime = 1000 / step;
		let i = 1;
		const interval = setInterval(() => {
			this.element.innerHTML = parseInt(i / step * this.value);

			if (i === step) {
				clearInterval(interval);
				this.status = CounterStatus.DONE;
			} else i++;
		}, intervalTime);
	}

	reset() {
		this.status = CounterStatus.WAIT;
		this.element.innerHTML = 0;
	}

	setElement(element) {
		this.element = element;
		return this;
	}

	setValue(value) {
		this.value = value;
		return this;
	}

	scrollHandler() {
		if (this.isWaiting && this.isVisible) this.animate();
		if (this.isDone && this.isDown) this.reset();
	}
}
export default () => {
	const counters = [...document.querySelectorAll('[data-counter]')].map((element) => {
		const counter = new Counter();
		counter
			.setElement(element)
			.setValue(element.dataset.counter);

		return counter;
	});

	window.addEventListener('scroll', () => {
		counters.forEach((counter) => counter.scrollHandler());
	});

	if (document.querySelector('[data-counter2]')) {
		setTimeout(() => {
			const counter = function (e, val, time) {
				const step = 200;
				const intervalTime = time / step;
				let i = 1;
				var interval = setInterval(() => {
					e.innerHTML = parseInt(i / step * val);

					if (i === step) { clearInterval(interval); } else { i++; }
				}, intervalTime);
			};

			const data = [...document.querySelectorAll('[data-counter2]')];
			const element = data[0];
			const time = 2000;
			let start = false;
			if ((element.getBoundingClientRect().top / window.innerHeight) < 0.9 && !start) {
				for (let i = 0; i < data.length; i++) {
					start = true;
					const o = data[i];
					counter(o, o.dataset.counter2, time);
				}
			} else {
				window.addEventListener('scroll', () => {
					if ((element.getBoundingClientRect().top / window.innerHeight) < 0.9 && !start) {
						start = true;
						for (let i = 0; i < data.length; i++) {
							const o = data[i];
							counter(o, o.dataset.counter2, time);
						}
					}
				});
			}
		}, 200);
	}
};
