import TypeFactory from './TypeFactory';

export default class TypeService {
	typeList = [];

	typeFilterList = [];

	constructor() {
		const typeFactory = new TypeFactory();
		this.typeList = typeFactory.createTypes();
		this.initTypes();
	}

	get filterList() {
		return this.typeFilterList;
	}

	initTypes() {
		this.typeList.forEach((type) => {
			type.element.addEventListener('click', () => this.addTypeToFilter(type));
		});
	}

	addEventListerToTypes(func) {
		this.typeList.forEach((type) => {
			type.element.addEventListener('click', func);
		});
	}

	addTypeToFilter(typeElement) {
		if (this.typeFilterList.includes(typeElement.id)) {
			const index = this.typeFilterList.indexOf(typeElement.id);
			this.typeFilterList.splice(index, 1);
			return;
		}
		this.typeFilterList.push(typeElement.id);
	}
}
