/* eslint-disable */
import 'regenerator-runtime/runtime';
import scrollToElement from './modules/scrollToElement';
import fixedNavbar from './modules/fixedNavbar';
import hideEmails from './modules/hideEmails';
import amazingScroll from './modules/amazingScroll';
import initTabNodes from './modules/tab';
import initAccordionNodes from './modules/accordion';
import numberCounter from './modules/counter.js';
import Timeline from './modules/timeline';
import QuickQuoteModal from './modules/form/Init.js';
import realizationInit from "./modules/Realizations";

const animationList = {
	'fade-top': 'top:-100px->0; transition:all 0s->all 1s; opacity:0->1;',
	'fade-right': 'opacity: 0->1; right:-500px->0; transition:all 0s->all 1s',
	'fade-bottom': 'bottom:-100px->0; transition:all 1s; opacity:0->1;',
	'fade-left': 'opacity: 0->1; left:-500px->0; transition:all 0s->all 1s',
	'fade-left-top': 'opacity: 0->1; left:-50px->0; top:-50px->0; transition:all 0s->all 1s',
	'fade-left-bottom': 'opacity: 0->1; left:-50px->0; bottom:-50px->0; transition:all 0s->all 1s',
	opacity: 'opacity: 0->1; transition:all 0s->all 1s',
	enlarge: 'transform:scale(0.5)->scale(1); opacity: 0->1; transition:all 0s->all 1s',
	shrink: 'transform:scale(1)->scale(0.5); opacity: 0->1; transition:all 0s->all 1s',
	rotate: 'transform:scale(0) rotate(-720deg)->scale(1) rotate(0deg); opacity: 0->1; transition:all 0s->all 1s',
};

document.addEventListener('DOMContentLoaded', () => {

	// [...document.querySelectorAll('a')].forEach((link) => {
  //   const { pathname, hash } = link;

  //   // Sprawdzenie, czy link jest częścią elementu SVG
  //   if (link.closest('svg') || hash.includes('#systemy-gorne') || hash.includes('#systemy-dolne') || hash.includes('#systemy-wysokie')) {
  //       return; // Jeśli link jest w SVG, nie zmieniaj href
  //   }

  //   if (
	// 		pathname === '/' ||
	// 		pathname === '/dlaczego-my/producent-mebli/historia-firmy' ||
	// 		pathname === '/dlaczego-my/producent-mebli/opinie-i-referencje' ||
	// 		pathname === '/dlaczego-my/producent-mebli/nasi-partnerzy' ||

	// 		pathname === '/dlaczego-my/technologie/montaz' ||
	// 		pathname === '/dlaczego-my/technologie/systemy' ||
	// 		pathname === '/systemy-szafek-kuchennych' ||
	// 		pathname === '/systemy-szafek-pokojowych' ||
	// 		pathname === '/dlaczego-my/technologie/wyposazenie' ||

	// 		pathname === '/dlaczego-my/wyrozniki/gwarancja' ||
	// 		pathname === '/dlaczego-my/wyrozniki/darmowe-projekty' ||
	// 		pathname === '/dlaczego-my/wyrozniki/bonusy' ||
	// 		pathname === '/dlaczego-my/wyrozniki/probny-trial' ||

	// 		pathname === '/dlaczego-my/materialy/fronty-i-plyty-meblowe' ||
	// 		pathname === '/dlaczego-my/materialy/blaty-robocze' ||
	// 		pathname === '/dlaczego-my/materialy/elementy-ozdobne' ||
	// 		pathname === '/dlaczego-my/materialy/elementy-tapicerowane' ||
	// 		pathname === '/dlaczego-my/materialy/uchwyty-i-systemy-bezuchwytowe' ||

	// 		pathname === '/dlaczego-my/praca-kariera' ||

	// 		pathname === '/meble-na-wymiar/meble-kuchenne' ||
	// 		pathname === '/meble-na-wymiar/meble-pokojowe/szafy-na-wymiar' ||
	// 		pathname === '/meble-na-wymiar/meble-pokojowe/garderoby-na-wymiar' ||
	// 		pathname === '/meble-na-wymiar/meble-lazienkowe' ||
	// 		pathname === '/meble-na-wymiar/meble-miekkie' ||

	// 		pathname === '/odnowa-mebli-na-wymiar' ||
	// 		pathname === '/proces-obslugi' ||


	// 		pathname === '/wspolpraca' ||
	// 		pathname === '/dla-projektantow-wnetrz' ||
	// 		pathname === '/dla-sprzedawcow-z-europy' ||
	// 		pathname === '/dla-salonow-sprzedazowych' ||
	// 		pathname === '/dla-deweloperow' ||

	// 		pathname === '/realizacje' ||
	// 		pathname === '/realizacje/realizacja-na-potokowej' ||
	// 		pathname === '/realizacje/realizacja-na-nefrytowej' ||
	// 		pathname === '/realizacje/realizacja-w-wilkszynie' ||
	// 		pathname === '/realizacje/realizacja-w-wilkszynie-graniczna' ||
	// 		pathname === '/realizacje/realizacja-w-domaszczynie' ||
	// 		pathname === '/realizacje/realizacja-drabika' ||
	// 		pathname === '/realizacje/realizacja-w-miedzianej' ||
	// 		pathname === '/realizacje/realizacja-w-nowomiejskiej' ||
	// 		pathname === '/realizacje/realizacja-na-tunelowej' ||
	// 		pathname === '/realizacje/inspiracja-moderno' ||
	// 		pathname === '/kontakt'

  //   ) {
	// 		link.href = pathname;
  //   } 
		
	// 	else {
	// 		link.href = '#!';
  //   }
	// })


	// Init modal szybka wycena 
	QuickQuoteModal()

	// Akordiony Bootstrap
	const Collapse = require('./modules/collapse.js').default;
	const collapses = [...document.querySelectorAll('[data-toggle="collapse"]')];
	if (collapses.length) {
		collapses.forEach((node) => new Collapse(node));
	}

	const emailhide = [...document.querySelectorAll('[rekiny_cloak=""]')];
	if (emailhide.length) {
		emailhide.forEach((link) => {
			const href = `mailto:${link.dataset.domain}@${link.dataset.account}`;
			link.setAttribute('href', href);
		});
	}
	// Własne selecty
	hideEmails();
	scrollToElement(document.querySelector('nav.navbar').offsetHeight);
	// Licznik wyłączony
	numberCounter()

	if (window.innerWidth >= 1200) {
		window.animate = amazingScroll({
			start: {
				visibleAfterLoading: true,
				waitForScroll: {
					on: false,
					ratio: 0,
				},
			},
			animation: animationList,
		});
	}

	fixedNavbar();

	if(window.innerWidth < 1200) {
		// Accordiony
		const accordionNodes = document.querySelectorAll('.accordion-wrapper')
		if (accordionNodes) {
			// initAccordionNodes(accordionNodes)
		}
	}

	// Taby
	const tabNodes = document.querySelectorAll('.tab')
	if (tabNodes) {
		// initTabNodes(tabNodes)
	}

	// if(window.innerWidth > 1199) {
	// 	let citySelectBtn = document.querySelector('.select-city')
	// 	let citySelectDropdown = document.querySelector('.select-city.desktop-visible .dropdown-menu')

	// 	citySelectBtn.addEventListener('click', () => {
	// 		citySelectBtn.classList.toggle('active')
	// 		citySelectDropdown.classList.toggle('show')
	// 	})
	// }


	// if(window.innerWidth < 1200) {
	// let citySelectBtnMobile = document.querySelector('.select-city-mobile')
	// let citySelectDropdownMobile = document.querySelector('.select-city-mobile .dropdown-menu')

	// citySelectBtnMobile.addEventListener('click', () => {
	// 	citySelectBtnMobile.classList.toggle('active')
	// 	citySelectDropdownMobile.classList.toggle('show')
	// })
	// }

	let menuBtn = document.querySelector('.navbar-toggler')

	menuBtn.addEventListener('click', () => {
		menuBtn.classList.toggle('active')
	})

	// Proces powstawania mebli
	let creationSection = document.querySelector('#creation-process')
	let creationStep = document.querySelectorAll('.process-tab__buttons button')
	let processWrapper = document.querySelector('.process-wrapper')
	let stepName = ''

	if(creationSection) {
		if(window.innerWidth > 1199) {
			creationStep.forEach( step => {
				step.addEventListener('click', () => {
					stepName = step.innerText.replace(/\s+/g, '-').toLowerCase().slice(2)
					let result = stepName.normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/\u0142/g, "l")
					processWrapper.style.backgroundImage = `url(` + window.baseURL + `images/proces-powstawania-mebli/${result}.jpg`
				})
			})
		}
	}

	let productBtn = document.querySelectorAll('.category-list-category')
	let accordionContent = document.querySelectorAll('.accordion-content')

	productBtn.forEach((btn, i) => {
		btn.addEventListener('click', () => {

			accordionContent.forEach(accordion => {
				accordion.style.maxHeight = 0
			})
			if(btn.parentNode.querySelector('.accordion-content')) {
				btn.parentNode.querySelector('.accordion-content').style.maxHeight = btn.parentNode.querySelector('.accordion-content').scrollHeight + "px"
			}
		})
		
	})

	//Galeria przykładów

	let exampleAttributesBtn = document.querySelectorAll('.example-attributes-btn')
	let exampleAttributesItems = document.querySelectorAll('.images-boxes.show-more')

	exampleAttributesItems.forEach((item, e) => {
		exampleAttributesBtn.forEach((btn, i) => {
			btn.addEventListener('click', () => {
				exampleAttributesItems[i].querySelectorAll('.extra-attribute.d-none').forEach(function(attribute) {
					attribute.classList.remove('d-none');
				});
				btn.classList.add('show-more')
			})
		})
	})

	//Galeria atrybotów

	let subcategoryAttributesBtn = document.querySelectorAll('.subcategory-attributes-btn')
	let subcategoryAttributesItems = document.querySelectorAll('.subcategory-attributes-items.show-more')

	subcategoryAttributesItems.forEach((item, e) => {
		subcategoryAttributesBtn.forEach((btn, i) => {
			btn.addEventListener('click', () => {
				subcategoryAttributesItems[i].querySelectorAll('.extra-attribute.d-none').forEach(function(attribute) {
					attribute.classList.remove('d-none');
				});
				btn.classList.add('show-more')
			})
		})
	})

	//Opinie

	const showOpinionsButton = document.querySelector('.show-opinions');
	if(showOpinionsButton) {
		showOpinionsButton.addEventListener('click', function(event) {
				event.preventDefault();
				document.querySelectorAll('.extra-opinion.d-none').forEach(function(opinion) {
						opinion.classList.remove('d-none');
				});
				
				// Opcjonalnie: ukryj przycisk po wyświetleniu wszystkich opinii
				showOpinionsButton.style.display = 'none';
		});
	}

	// SVG z meblami
	let svgPaths = document.querySelectorAll('.furniture-segments svg path')
	let systemBoxes = document.querySelectorAll('.system-box')
	let systemBoxesWrapper = document.querySelector('.system-boxes')
	let counter = 0;

	if(systemBoxesWrapper) {
		svgPaths.forEach( (path, i) => {
			counter++
			systemBoxes.forEach( (systemBox, k) => {

			path.addEventListener('mouseover', () => {
				systemBoxes[i].classList.add('active')
			})

			path.addEventListener('mouseout', () => {
				systemBoxes[i].classList.remove('active')
			})
			
			systemBox.addEventListener('mouseover', () => {
				svgPaths[k].classList.add('active')
			})

			systemBox.addEventListener('mouseout', () => {
				svgPaths[k].classList.remove('active')
			})

			})
		})
	}

	//Opóźnienie wczytywanie filmu hero
	let heroVideo = document.querySelector('.hero__background')

	if(heroVideo) {
		function addVideoSrc() {
			heroVideo.play()
		}

		setTimeout(addVideoSrc, 5000);
	}	

	let categoryLists = document.querySelector('.category-lists')
	let accordionsBtns = document.querySelectorAll('button.category-list-category')

	if(categoryLists) {
		accordionsBtns.forEach(btn => {
			btn.addEventListener('click', function(e) {
				e.preventDefault()
			})
		});
	}
})

document.addEventListener('DOMContentLoaded', function () {
	// Get all of the videos
	let videos = document.querySelectorAll('[data-youtube]');
	// Progressively enhance them
	for (let video of videos) {
		// Get the video ID
		let id = new URL(video.dataset.url).searchParams.get('v')
		// Add the ID to the data-youtube attribute
		video.setAttribute('data-youtube', id)

		// Add a thumbnail
		if(video.getAttribute('data-youtube-image')) {
			video.style.backgroundImage = `url('${video.getAttribute('data-youtube-image')}')`;
		}	else {
			video.style.backgroundImage = `url('https://img.youtube.com/vi/${id}/maxresdefault.jpg')`;
		} 
	}

	function clickHandler (event) {
		// Get the video link
		let link = event.target.closest('[data-youtube]')
		if (!link) return
	
		// Prevent the URL from redirecting users
		event.preventDefault()
	
		// Get the video ID
		let id = link.getAttribute('data-youtube')
	
		// Create the player
		let player = document.createElement('div')
		player.innerHTML = `<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${id}?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
		player.classList.add('youtube-box__iframe')
		// Inject the player into the UI
		link.replaceWith(player)
	}
	
	// Detect clicks on the video thumbnails//
	document.addEventListener('click', clickHandler)
})

document.addEventListener('DOMContentLoaded', function () {
	let procesObslugiPage = document.querySelector('body.proces-obslugi')
	let realizations = document.querySelector('body.realizacje')
	// Get all of the videos from Google Drive
	let videos = document.querySelectorAll('[data-video]');
	// Progressively enhance them
	for (let video of videos) {
		// Get the video ID
		if(procesObslugiPage) {
			let id = video.dataset.url.split('video/').pop().replace('.mp4', '')

			// Add the ID to the data-video attribute
			video.setAttribute('data-video', id)

			// Add a thumbnail
			video.style.backgroundImage = `url('` + window.baseURL + `images/poznaj-proces-obslugi/${id}.jpg')`;
		} else if(realizations) {
			let id = video.dataset.url.split('filmy/').pop().replace('.mp4', '')

			// Add the ID to the data-video attribute
			video.setAttribute('data-video', id)

			// Add a thumbnail
			video.style.backgroundImage = `url('` + window.baseURL + `images/realizacje/filmy/${id}.jpg')`;
		}

	}
	function clickHandler (event) {
		// Get the video link
		let link = event.target.closest('[data-video]')
		if (!link) return
	
		// Prevent the URL from redirecting users
		event.preventDefault()
	
		// Get the video ID
		let id = link.getAttribute('data-video')
	
		// Create the player
		let player = document.createElement('div')
		if(procesObslugiPage) {
			player.innerHTML = `<video width="100%" height="100%" controls autoplay><source src="` + window.baseURL + `images/poznaj-proces-obslugi/video/${id}.mp4" type="video/mp4"></video>`
		} else if (realizations) {
			player.innerHTML = `<video width="100%" height="100%" controls autoplay><source src="` + window.baseURL + `images/realizacje/filmy/${id}.mp4" type="video/mp4"></video>`
		}
		player.classList.add('youtube-box__iframe')
		// Inject the player into the UI
		link.replaceWith(player)
	}
	
	// Detect clicks on the video thumbnails
	document.addEventListener('click', clickHandler)
})


document.addEventListener('DOMContentLoaded', () =>{
	if(location.pathname === "/realizacje") realizationInit()
})