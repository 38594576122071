import Category from './Category';

export default class CategoryFactory {
	categoryClassName = 'Realizations__category_filter_btn';

	createCategories() {
		const categoryButtonList = document.getElementsByClassName(this.categoryClassName);
		// eslint-disable-next-line max-len
		const categoryList = [];
		for (let i = 0; i < categoryButtonList.length; i++) {
			const categoryButton = categoryButtonList[i];
			categoryList.push(new Category(categoryButton.id, categoryButton.name, categoryButton));
		}
		return categoryList;
	}
}
