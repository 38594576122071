import CategoryFactory from './CategoryFactory';
import CategoryClearButton from './CategoryClearButton';

export default class CategoryService {
	categoryList = [];

	currentCategory = null;

	categoryClearBtn;

	constructor() {
		const categoryFactory = new CategoryFactory();
		this.categoryList = categoryFactory.createCategories();
		this.categoryClearBtn = new CategoryClearButton();
		this.initCategories();
		this.initCategoryClear();
	}

	get filterList() {
		return this.currentCategory;
	}

	initCategories() {
		this.categoryList.forEach((category) => {
			category.element.addEventListener('click', () => this.setCategoryFilter(category));
		});
	}

	initCategoryClear() {
		if (!this.categoryClearBtn.element) return;
		this.categoryClearBtn.element.addEventListener('click', () => this.clearCategoryFilters());
	}

	addEventListerToCategories(func) {
		this.categoryList.forEach((category) => {
			category.element.addEventListener('click', func);
		});
	}

	addEventListenerToClearBtn(func) {
		this.categoryClearBtn.element.addEventListener('click', func);
	}

	clearCategoryFilters() {
		this.currentCategory = null;
		this.categoryList.forEach((category) => category.element.classList.remove('Realizations__category_btn--active'));
		this.categoryClearBtn.element.classList.add('Realizations__category_btn--active');
	}

	setCategoryFilter(categoryElement) {
		this.categoryList.forEach((category) => category.element.classList.remove('Realizations__category_btn--active'));
		if (this.currentCategory === categoryElement.id) {
			this.currentCategory = null;
			return;
		}
		this.categoryClearBtn.element.classList.remove('Realizations__category_btn--active');
		this.currentCategory = categoryElement.id;
		categoryElement.element.classList.add('Realizations__category_btn--active');
	}
}
