/* eslint-disable */

class AccordionWrapper {
	constructor (accordionElement) {
		this.accordions = [...accordionElement.children].map(element => new Accordion(this, element))
	}

	openElement (element) {
		this.accordions.map(el => {
			if (el.element === element) el.open()
			else el.close()
		})
	}
}
class Accordion {
	constructor (accordionWrapper, element) {
        this.accordionWrapper = accordionWrapper
        this.element = element
        this.button = element.querySelector('.accordion-button')
        this.content = element.querySelector('.accordion-content')
        this.arrow =  this.button.querySelector('.arrow')
		this.isOpen = false
		this.registerListener()
	}

	registerListener () {
		this.button.addEventListener('click', () => this.toggle())
	}
	toggle () {
		if (this.isOpen) {
			this.close()
		} else {
			this.accordionWrapper.openElement(this.element)
		}
	}
	open () {
		this.isOpen = true
		this.render()
	}
	close () {
		this.isOpen = false
		this.render()
	}
	render () {
		if(!this.content) return ;
		if (this.isOpen) {
            this.content.style.maxHeight = this.content.scrollHeight + "px";
			this.arrow.classList.add('open')
		} else {

            this.content.style.maxHeight = null;
			this.arrow.classList.remove('open')
		}
    }   
}


document.addEventListener('DOMContentLoaded', () => {
	const elements = document.querySelectorAll('.accordion-wrapper')
	if (elements) {
		[...elements].forEach(x => {
		new AccordionWrapper(x)
		})
	}
})
